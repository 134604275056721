import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export interface ICustomerTransaction extends IBaseEntity {
    id?: number;
    transactionDate: any;
    customer: string;
    transactionAmount: number;
    previousBalance: number;
    newBalance: number;
    route?: string;
    deliveryPoint?: string;
    driver?: string;
    invoiceNo?: string;
    creditNoteNo?: string;
    paymentReference?: string;
}

export class CustomerTransaction extends BaseEntity implements ICustomerTransaction {
    constructor(customerTransaction?) {
        super();
        if (customerTransaction) {
            this.id = customerTransaction.id;
            this.transactionDate = customerTransaction.transactionDate;
            this.customer = customerTransaction.customer;
            this.transactionAmount = customerTransaction.transactionAmount;
            this.previousBalance = customerTransaction.previousBalance;
            this.newBalance = customerTransaction.newBalance;
            this.route = customerTransaction.route;
            this.deliveryPoint = customerTransaction.deliveryPoint;
            this.driver = customerTransaction.driver;
            this.invoiceNo = customerTransaction.invoiceNo;
            this.paymentReference = customerTransaction.paymentReference;
            this.creditNoteNo = customerTransaction.creditNoteNo;
        }
    }

    get discriminator(): any {
        return this.id;
    }
    public id?: number;
    public transactionDate: MposDate;
    public customer: string;
    public transactionAmount: number;
    public previousBalance: number;
    public newBalance: number;
    public route?: string;
    public deliveryPoint?: string;
    public driver?: string;
    public invoiceNo?: string;
    public paymentReference?: string;
    public creditNoteNo?: string;

    string;

    clone(): CustomerTransaction {
        return new CustomerTransaction(this);
    }
}
